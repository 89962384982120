import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import AppLayout from 'layouts/AppLayout'
import GenericPage from 'components/genericPage/GenericPageView'
import globalRequest from 'common/utils/globalRequest'
import getCampaignBanner from 'common/utils/getCampaignBanner'


export async function getStaticProps({ locale }) {
  const initialReduxStore = await globalRequest(locale)
  const notFoundData = get(initialReduxStore, 'cmsInfo.data.not_found_page', { content: [] })
  if(!notFoundData) {
    return { props: { locale } }
  }
  const campaignBanner = getCampaignBanner({ slug: '404', homeSlug: get(initialReduxStore, 'cmsInfo.data.home_page_slugs.' + locale), banners: get(initialReduxStore, 'cmsInfo.data.campaign_banners') })
  return { props: { initialReduxStore: { ...initialReduxStore, ...campaignBanner }, locale, translations: initialReduxStore.translations.data, notFoundData }, revalidate: process.env.REVALIDATE_404_PAGE }
}

HomePageComponent.propTypes = {
  notFoundData: PropTypes.object.isRequired,
}

export default function HomePageComponent({ notFoundData }) {
  const isHeaderFooterEnabled = useMemo(() => ({
    isHeaderEnabled: get(notFoundData, 'display_header', true),
    isFooterEnabled: get(notFoundData, 'display_footer', true),
  }), [notFoundData])
  return (
    <AppLayout {...isHeaderFooterEnabled}>
      <GenericPage data={notFoundData} />
    </AppLayout>
  )
}
